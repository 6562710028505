@import "_config.scss";

html {
    font-size: 12px;
}

body {
    background-color: transparent;
}

div.App {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #fff;
}

div.App.inFrame {
    background: transparent;
}

.endsWithPageBreak {
    page-break-after: always;
}

input[type=text],
div.react-datepicker__input-container > input {
    padding: 9px 12px;
    border: 1px solid rgb(206, 212, 218);
    border-radius: 0.375rem;
}

div.taskHistoryLine div:first-child {
    font-weight: bold;
}

div.taskHistoryContainer {
    max-height: 160px;
    overflow: auto;
}

.sel_taskStep {
    width: 161px;
}

table.tasksTable {
    min-height: 200px;
}

table.salesTable td:nth-child(1),
table.salesTable th:nth-child(1) {
    display: none;
}

.tableLine.holidayStatus3 {
    opacity: 0.3;
}

.tableLine.holidayStatus1 {
    color: #f3cc5c;
}

div.filters input {
    max-width: 161px;
}

.programUser .hideForProgram {
    display: none;
}

div.leftMenu {
    width: 190px;
}

div.contentPanel {
    width: calc(100% - 210px) !important;
    position: absolute;
    height: 100%;
    left: 190px;
}

div.InvoiceViewer {
    z-index: 10000;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: auto;
    background: #00000088;
    padding: 20px;
}

div.InvoiceViewer .InvoiceContents {
    background: #fff;
    width: 1024px;
    margin-left: auto;
    margin-right: auto;
    padding: 40px;
    border-radius: 8px;
}

div.logo img {
    width: 100%;
}

div.border-2 {
    border: 2px solid #000;
}

div.CompanyAnnouncements {
    max-height: 300px;
    overflow-y: auto;
}

div.CompanyAnnouncements h5 {
    font-size: 12px;
}

div.CompanyAnnouncements input {
    width: calc(100% - 70px);
    display: inline-block;
    margin-right: 10px;
}

div.CompanyAnnouncements div.ann {
    border-bottom: 1px dashed #cecece;
}

div.CompanyAnnouncements span.meta {
    color: #999;
}

table.listingTable button {
    padding-top: 2px;
    padding-bottom: 2px;
}

table.listingTable tr:last-child td {
    border-bottom: 1px solid #cecece;
}

table.listingTable tr td {
    border-top: 1px solid #cecece;
    border-bottom: none; 
}

table.listingTable tr.groupped td:first-child {
    border-top: none;
}

div.taskHistoryLine div.highlighted {
    font-weight: 700;
    color: #6100c2;
}

div.taskHistoryLine {
    margin-bottom: 6px;
    padding-bottom: 6px;
    border-bottom: 1px dashed #bbb;
}

tr.task,
tr.memberline {
    print-color-adjust: exact;
}

tr.task.hot {
    background: #fcf1f1;
    
}

tr.totalLine {
    background:#cecece;
    print-color-adjust: exact;
    font-weight: bold;
}

tr.memberline.expired {
    background: #fcf1f1;
    print-color-adjust: exact;
}

tr.memberline.expiresSoon {
    background: #e9ff79;
    print-color-adjust: exact;
}

tr.memberline.expires3M {
    background: #f6ffc7;
    print-color-adjust: exact;
}

.modal.fade.show {
    backdrop-filter: blur(5px);
}

.printOnly {
    display: none;
}

div.productLine .selectField {
    width: calc(100% - 103px);
    display: inline-block;
}

div.productLine .priceField {
    width: 60px;
    display: inline-block;
    margin-left: 5px;
}

div.productLine .priceField input {
    line-height: 27px;
    -moz-appearance: textfield;
}

div.productLine .priceField input::-webkit-outer-spin-button,
div.productLine .priceField input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

img.contactPhoto {
    border-radius: 60px;
    width: 100%;
}

div.w300px {
    width: 200px;
}

span.highlight {
    background: #e9ff79;
    padding: 5px;
}

.menuItemProducts,
.menuItemSales,
.menuItemTasks {
    margin-bottom: 20px;
}

div.newsletterPreviewController {
    position: fixed;
    bottom: 20px;
    right: 20px;
}

div.newsletterPreviewController button {
    padding: 10px 20px;
    font-size: 20px;
}

h3.transactionsTotal span {
    display: none;
}

h3.transactionsTotal:hover span {
    display: inline;
}

div.bottomPage {
    margin-top: 100px;
}

table.sticky th {
    position: sticky;
    top: 0;
    background-color: #F9F8F8;
}

@media print {
    .printOnly { display: auto;}
    .printOnly.totalLine { display: table-row;}
    .noPrint { display: none !important; }

    div.contentPanel { height: auto; left:0; width: 100% !important;}
    div.contentPanelContents {height: auto !important; position: relative !important;}

    div.doPrint { 
        display: block !important;
    }

    div.InvoiceViewer {
        position: absolute;
        bottom: auto;
        height: 250mm;
    }

    div.InvoiceViewer .InvoiceContents {
        background: #fff;
        width: 100%;
        padding: 0px;
        border-radius: 0;
    }

    div.salesUpdate {
        position: relative;
        top: 0;
        left: 0;
        right: 0;

    }

    div.bottomPage img {
        margin-top: 20px;
    }
    div.bottomPage {
        background: #fff;
        left: 0;
        right: 10px;
        position: absolute;
        bottom: 0; 
    }

    div.eventEditionBrowserModal
    {
        width: 100vw;
        background: #fff;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        min-height: 100vh;
        height: auto;
        margin-left: 0;
        margin-right: 0;
        padding: 0;
    }

    div.eventEditionBrowserModal .modal-dialog {
        margin: 0;
        width: 100vw;
        max-width: 100vw;
        border: none;
    }

    div.eventEditionBrowserModal .modal-content {
        border: none;
    }

    
    div.eventEditionBrowserModal .modal-header,
    div.eventEditionBrowserModal .modal-footer,
    div.eventEditionBrowserModal .nav,
    .download-table-xls-button {
        display: none;
    }
}

div.eventRegistrationForm div.buttonsContainer {
    text-align: center;
}

div.eventRegistrationForm div.buttonsContainer .sub {
    color: #f3f3f3;
    margin-bottom: 10px;
    font-size: 10px;

}

div.eventRegistrationForm button,
div.eventRegistrationForm a.btn {
    max-width: calc(100% - 10px);
    width: 200px;
}

div.eventRegistrationForm button.unselected {
    opacity: 0.5;
}

div.eventRegistrationForm {
    background: #00000088;
    border: 1px solid #fff;
}

div.eventRegistrationForm .control {
    padding: 10px 40px;
}

div.eventRegistrationForm .control.buttonsContainer {
    padding: 20px 40px;
    border-top: 1px solid #fff;
}

div.eventRegistrationForm label {
    color: #fff;
    font-weight: 700;
    font-size: 12px;
    text-transform: uppercase;
    margin-top: 5px;
}

div.eventRegistrationForm label .asterisk {
    color: #ff0000;
}

div.eventRegistrationForm select {
    padding: 9px 12px;
}

div.radioSelect {
    margin-top: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid #fff;
}

div.radioSelect input {
    margin-right: 5px;
    display: inline;
}

div.radioSelect label {
    display: inline;
}

div.eventRegistrationForm a.btn,
div.eventRegistrationForm button {
    font-size: 18px;
}

div.eventRegistrationForm a.btn:not(:hover),
div.eventRegistrationForm button {
    background-color: #004084;
    border-color: #004084;
    color: #fff;
}

div.eventRegistrationForm a.btn:hover {
    background-color: #004084;
    border-color: #004084;
    opacity: 0.9;
}

div.eventRegistrationForm div.purchaseBtnContainer {
    text-align: center;
    color: #ccc;
    font-size: 12px;
    padding-top: 20px;
}

div.eventRegistrationForm div.purchaseBtnContainer span.bar {
    display: inline-block;
    height: 1px;
    width: 60px;
    background: #ccc;
    vertical-align: middle;
}

div.eventRegistrationForm input:focus:not([type='checkbox']),
div.eventRegistrationForm input:not([type='checkbox']) {
    color: #fff;
    background: none;
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: 0;
    outline: none !important;
}

div.eventRegistrationForm input[type='checkbox'] {
    margin-top: 7px;
}


div.ChatItems .messages {
    max-height: 500px;
    overflow-y: auto;
}

div.ChatItems .messages div.removed {
    opacity: 0.25;
}

i div.sendDetails {
    display: none;
}

i:hover div.sendDetails {
    display: block;
    position: absolute;
    padding: 20px;
    border-radius: 8px;
    background: #fff;
    right: 0;
}


.pForm {
    #root {
        display: flex;
        align-items: center;
      }
      
      body {
        font-family: -apple-system, BlinkMacSystemFont, sans-serif;
        font-size: 16px;
        -webkit-font-smoothing: antialiased;
        display: flex;
        justify-content: center;
        align-content: center;
        height: 100vh;
        width: 100vw;
      }
      
      form {
        width: 30vw;
        min-width: 500px;
        align-self: center;
        box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
          0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
        border-radius: 7px;
        padding: 40px;
      }
      
      
      #payment-message {
        color: rgb(105, 115, 134);
        font-size: 16px;
        line-height: 20px;
        padding-top: 12px;
        text-align: center;
      }
      
      #payment-element {
        margin-bottom: 24px;
      }
      
      /* Buttons and links */
      button {
        background: #5469d4;
        font-family: Arial, sans-serif;
        color: #ffffff;
        border-radius: 4px;
        border: 0;
        padding: 12px 16px;
        font-size: 16px;
        font-weight: 600;
        cursor: pointer;
        display: block;
        transition: all 0.2s ease;
        box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
        width: 100%;
      }
      
      button:hover {
        filter: contrast(115%);
      }
      
      button:disabled {
        opacity: 0.5;
        cursor: default;
      }
      
      /* spinner/processing state, errors */
      .spinner,
      .spinner:before,
      .spinner:after {
        border-radius: 50%;
      }
      
      .spinner {
        color: #ffffff;
        font-size: 22px;
        text-indent: -99999px;
        margin: 0px auto;
        position: relative;
        width: 20px;
        height: 20px;
        box-shadow: inset 0 0 0 2px;
        -webkit-transform: translateZ(0);
        -ms-transform: translateZ(0);
        transform: translateZ(0);
      }
      
      .spinner:before,
      .spinner:after {
        position: absolute;
        content: '';
      }
      
      .spinner:before {
        width: 10.4px;
        height: 20.4px;
        background: #5469d4;
        border-radius: 20.4px 0 0 20.4px;
        top: -0.2px;
        left: -0.2px;
        -webkit-transform-origin: 10.4px 10.2px;
        transform-origin: 10.4px 10.2px;
        -webkit-animation: loading 2s infinite ease 1.5s;
        animation: loading 2s infinite ease 1.5s;
      }
      
      .spinner:after {
        width: 10.4px;
        height: 10.2px;
        background: #5469d4;
        border-radius: 0 10.2px 10.2px 0;
        top: -0.1px;
        left: 10.2px;
        -webkit-transform-origin: 0px 10.2px;
        transform-origin: 0px 10.2px;
        -webkit-animation: loading 2s infinite ease;
        animation: loading 2s infinite ease;
      }
      
      @keyframes loading {
        0% {
          -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
        }
        100% {
          -webkit-transform: rotate(360deg);
          transform: rotate(360deg);
        }
      }
      
      @media only screen and (max-width: 600px) {
        form {
          width: 80vw;
          min-width: initial;
        }
      }
}

@import "../econf/custom.scss";